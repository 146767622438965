<template>
  <div>
    <BCard>
      <b-row class="justify-content-between mb-[24px]">
        <b-col
          cols="auto"
          class="flex items-center"
        >
          <h4><strong class="text-black text-2xl">Grup Kontak</strong></h4>
        </b-col>
      </b-row>
      <b-row class="justify-content-between mb-[24px]">
        <BCol
          class="mb-[5px] text-center"
          md="3"
        >
          <BInputGroup class="input-group-merge">
            <BInputGroupPrepend is-text>
              <feather-icon icon="SearchIcon" />
            </BInputGroupPrepend>
            <BFormInput
              v-model="search"
              size="md"
              placeholder="Cari berdasarkan Nama grup"
              style="padding: 8px 1rem 8px 0px"
              @input="searchData"
            />
          </BInputGroup>
        </BCol>
        <BCol
          class="mb-[5px] text-center"
          md="auto"
        >
          <b-dropdown
            id="dropdown-right"
            right
            text="+ Tambah"
            variant="primary"
            class="w-100"
          >
            <b-dropdown-item
              @click="checkConnectionGoogle"
            >
              <div class="flex items-center gap-[14px] item-list">
                <span>+</span>
                <span>Import dari Google</span>
                <img
                  width="20"
                  src="https://storage.googleapis.com/komerce/assets/LP-Komchat/google-icon.svg"
                  alt="icon google"
                >
              </div>
            </b-dropdown-item>
            <b-dropdown-item
              @click="importFromKomship"
            >
              <div class="flex items-center gap-[14px] item-list">
                <span>+</span>
                <span>Import dari Komship</span>
                <img
                  src="https://storage.googleapis.com/komerce/assets/LP-Komerce/komship.svg"
                  alt="icon komship"
                >
              </div>
            </b-dropdown-item>
            <b-dropdown-item @click="createManual">
              <div class="flex items-center gap-[14px] item-list">
                <span>+</span>
                <span>Buat Grup Kontak</span>
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </BCol>
      </b-row>
      <BOverlay
        :show="isLoading"
        spinner-variant="primary"
        variant="light"
        blur="0"
        opacity=".5"
        rounded="sm"
      >
        <div
          id="infinite-list"
          style="height: calc(100vh - 310px); overflow-y: scroll;"
        >
          <BTable
            id="table-grup"
            :items="items"
            :fields="fields"
            show-empty
            empty-text="Tidak ada data yang ditampilkan."
            responsive
            class="table-grup"
            hover
            :select-mode="selectMode"
            @row-clicked="handleToDetailFromTable"
          >

            <template #cell(name)="data">
              <div class="min-w-[140px] flex gap-2 items-center text-[#222]">
                <img
                  v-if="data.item.created_from === 'komship'"
                  src="https://storage.googleapis.com/komerce/assets/LP-Komerce/komship.svg"
                  alt="icon komship"
                >
                <img
                  v-if="data.item.created_from === 'google'"
                  width="22"
                  src="https://storage.googleapis.com/komerce/assets/LP-Komchat/google-icon.svg"
                  alt="icon komship"
                >
                <span class="text-sm text-[#828282]">
                  {{ data.item.name }}
                </span>
              </div>
            </template>
            <template #cell(createdDate)="data">
              <div class="min-w-[140px] text-[#222]">
                <span class="d-flex">
                  {{ data.item.createdDate }}
                </span>
                <span class="text-sm text-[#828282]">
                  {{ data.item.createdTime }} WIB
                </span>
              </div>
            </template>
            <template #cell(totalContact)="data">
              <div class="min-w-[140px] flex items-center text-[#222]">
                <div
                  v-if="data.item.import_excel_progress"
                  class="w-full flex items-center gap-1 justify-between"
                >
                  <div class="w-[90%]">
                    <b-progress
                      height="11px"
                      variant="success"
                      :max="data.item.import_excel_progress.contact_total"
                      :value="data.item.import_excel_progress.contact_success"
                    />
                  </div>
                  <div class="text-sm">
                    {{ `${Math.round(data.item.import_excel_progress.percentage)}%` }}
                  </div>
                </div>
                <div
                  v-else
                  class=" justify-center items-center text-start flex"
                >
                  {{ data.item.total_contact }}
                </div>
              </div>
            </template>

            <template #cell(action)="data">
              <div class="d-flex align-items-center gap-[15px]">
                <b-button
                  size="sm"
                  class="p-0"
                  variant="light"
                  style="background-color: transparent; border: none"
                  @click="handleToDetailFromTable(data.item); $event.stopPropagation()"
                >
                  <img
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/eye.svg"
                    class="max-w-[25px]"
                  >
                </b-button>
                <b-button
                  size="sm"
                  class="p-0"
                  variant="light"
                  style="background-color: transparent; border: none"
                  @click="editData(data.item); $event.stopPropagation()"
                >
                  <img
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/edit.svg"
                    class="max-w-[25px]"
                  >
                </b-button>
                <b-button
                  size="sm"
                  class="p-0"
                  variant="light"
                  style="background-color: transparent; border: none"
                  @click="deleteData(data.item); $event.stopPropagation()"
                >
                  <img
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/trash.svg"
                    class="max-w-[25px]"
                  >
                </b-button>
              </div>
            </template>
          </BTable>
        </div>
      </BOverlay>
      <BRow class="mt-1">
        <BCol
          cols="12"
          class="d-flex justify-content-between"
        >
          <div class="d-flex justify-content-center align-items-center gap-[16px]">
            <strong class="text-[#333]"> Total Grup Kontak </strong>
            <strong class="text-[#F95031]">{{ items.length || 0 }}</strong>
          </div>
        </BCol>
      </BRow>
    </BCard>
    <ModalDelete
      :id="dataDeleteGrupId"
      :get-all-kontak="getAllKontak"
    />
    <ModalEdit
      :id="dataEditGrupId"
      :name="dataEditGrupName"
      :get-all-kontak="getAllKontak"
    />
    <ModalCreate
      :data="dataCreateGrup"
      :get-all-kontak="getAllKontak"
    />
  </div>

</template>
<script>
import {
  BCol,
  BRow,
  BCard,
  BOverlay,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ModalDelete from './modal/ModalDeleteFolder.vue'
import ModalEdit from './modal/ModalEditGrup.vue'
import ModalCreate from './modal/ModalCreateGrup.vue'
import { apiSignInGoogle, apiSignInGoogleVerify, apiCheckConnectionGoogle } from './Broadcast/service/api.groupContact.service'

export default {
  components: {
    BCol,
    BRow,
    BCard,
    BOverlay,
    ModalDelete,
    ModalEdit,
    ModalCreate,
  },
  data() {
    return {
      loading: false,
      isLoading: false,
      selectMode: 'single',
      search: '',
      dataDeleteGrupId: 0,
      dataEditGrupName: '',
      dataEditGrupId: 0,
      dataCreateGrup: '',
      fields: [
        {
          key: 'name',
          label: 'Nama',
          thClass: 'font-bold text-black',
          class: 'align-middle text-black',
          sortable: true,
        },
        {
          key: 'createdDate',
          label: 'Tanggal Ditambahkan',
          thClass: 'font-bold',
          class: 'align-middle text-black',
        },
        {
          key: 'totalContact',
          label: 'Jumlah Kontak',
          thClass: 'font-bold',
          class: 'align-middle text-black',
        },
        {
          key: 'action',
          label: 'Aksi',
          thClass: 'font-bold',
          class: 'align-middle text-black',
        },
      ],
      items: [],
      dateTime: [],
      cursor: 0,
      limit: 10,
      lastData: false,
      anyImportProcess: false,
      progressInterval: null,
      isKomshipUser: this.$store.state.dashboard.profile.is_komship === 1,
      dialogUrl: '',
      token: '',
    }
  },
  mounted() {
    this.getAllKontak()
    const listElm = document.getElementById('infinite-list')
    listElm.addEventListener('scroll', e => {
      if (
        Math.ceil(listElm.scrollTop + listElm.clientHeight)
          >= listElm.scrollHeight
        && !this.loading && !this.lastData
      ) {
        this.nextData()
      }
    })
  },
  beforeDestroy() {
    this.stopProgressTracking()
  },
  methods: {
    async getAllKontak() {
      this.isLoading = !this.anyImportProcess
      const params = {
        search: this.search,
        limit: this.limit,
        cursor: 0,
      }
      await this.$http_new.get('/komchat-api/api/v1/group-contact/list', { params })
        .then(res => {
          const { data, meta } = res.data
          this.items = data.map(item => {
            const createdAtDate = new Date(item.created_at)
            const createdDate = createdAtDate.toLocaleDateString('id-ID', { day: '2-digit', month: 'long', year: 'numeric' })
            const createdTime = createdAtDate.toLocaleTimeString('id-ID', { hour12: false, hour: '2-digit', minute: '2-digit' })
            return {
              ...item,
              createdDate,
              createdTime,
            }
          })
          this.findProgressImport(data)
          this.isLoading = false
          this.cursor = meta.pagination.next_cursor
          if (data.length < this.limit || this.cursor === undefined) this.lastData = true
          else this.lastData = false
        })
        .catch(err => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Gagal',
                icon: 'AlertCircleIcon',
                text: err.message,
                variant: 'danger',
              },
            },
            2000,
          )
          this.isLoading = false
        })
    },
    async nextData() {
      this.isLoading = true
      const params = {
        search: this.search,
        limit: this.limit,
        cursor: this.cursor,
      }
      await this.$http_new.get('/komchat-api/api/v1/group-contact/list', { params })
        .then(res => {
          const { data, meta } = res.data
          const newItems = data.map(item => {
            const createdAtDate = new Date(item.created_at)
            const createdDate = createdAtDate.toLocaleDateString('id-ID', { day: '2-digit', month: 'long', year: 'numeric' })
            const createdTime = createdAtDate.toLocaleTimeString('id-ID', { hour12: false, hour: '2-digit', minute: '2-digit' })
            return {
              ...item,
              createdDate,
              createdTime,
            }
          })
          this.findProgressImport(data)
          this.items.push(...newItems)
          this.isLoading = false
          this.cursor = meta.pagination.next_cursor
          if (data.length < this.limit || this.cursor === undefined) this.lastData = true
          else this.lastData = false
        })
        .catch(err => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Gagal',
                icon: 'AlertCircleIcon',
                text: err.message,
                variant: 'danger',
              },
            },
            2000,
          )
          this.isLoading = false
        })
    },
    searchData: _.debounce(function search() {
      this.getAllKontak()
    }, 1000),
    findProgressImport(data) {
      const dataProcess = data.filter(item => {
        const process = item.import_excel_progress
        return process
      })
      this.anyImportProcess = dataProcess.length > 0
      if (this.anyImportProcess) {
        this.startProgressTracking()
      } else {
        this.stopProgressTracking()
      }
    },
    startProgressTracking() {
      if (!this.progressInterval) {
        this.progressInterval = setInterval(this.getAllKontak, 10000)
      }
    },
    stopProgressTracking() {
      if (this.progressInterval) {
        clearInterval(this.progressInterval)
        this.progressInterval = null
      }
    },
    editData(dataEdit) {
      this.dataEditGrupId = dataEdit.id
      this.dataEditGrupName = dataEdit.name
      this.$bvModal.show('modal-edit')
    },
    deleteData(dataDelete) {
      this.dataDeleteGrupId = dataDelete.id
      this.$bvModal.show('modal-delete-folder')
    },
    createManual() {
      this.$bvModal.show('modal-create')
    },
    importFromKomship() {
      if (!this.isKomshipUser) {
        window.open('https://komship.id/pendaftaran')
      } else {
        this.$router.push({
          path: '/import-from-komship',
        })
      }
    },
    async importFromGoogle() {
      try {
        const { data } = await apiSignInGoogle()
        this.dialogUrl = data.data.url
        this.openGoogleSignIn()
      } catch (error) {
        this.$toast_error({ message: this.capitalizeWords(error.response.data.meta.message) })
      }
    },

    handleToDetailFromTable(data) {
      const dataGroup = JSON.stringify(data)
      this.$router.push({
        name: '/grup-contact/list-contact',
        params: { data: dataGroup },
      })
    },
    async checkConnectionGoogle() {
      try {
        const { data } = await apiCheckConnectionGoogle()
        const isConnect = data.data.is_connect
        if (isConnect) {
          this.token = data.data.token
          this.redirectToListContact()
        } else {
          this.importFromGoogle()
        }
      } catch {
        this.$toast_error('Gagal cek login google')
      }
    },
    openGoogleSignIn() {
      this.authWindow = window.open(this.dialogUrl, 'Google Sign-In', 'width=600,height=700')
      this.pollOAuthData()
    },
    pollOAuthData() {
      const pollingInterval = setInterval(() => {
        try {
          const oauthData = localStorage.getItem('google_oauth_code')
          if (oauthData) {
            const { code } = JSON.parse(oauthData)
            localStorage.removeItem('google_oauth_code')
            clearInterval(pollingInterval)
            this.verifySignInGoogle(code)
          }
          if (this.authWindow.closed) {
            clearInterval(pollingInterval)
          }
        } catch (error) {
          this.$toast_error({ message: this.capitalizeWords(error) })
        }
      }, 1000)
    },
    redirectToListContact() {
      this.$router.push(`/grup-contact/list-contact-google?token=${this.token}`)
    },
    async verifySignInGoogle(code) {
      try {
        const { data } = await apiSignInGoogleVerify(code)
        this.token = data.data.token
        this.redirectToListContact()
      } catch (err) {
        this.$toast_error({ message: 'Verifikasi Gagal, Silahkan coba lagi!' })
      }
    },

  },

}
</script>

<style scoped>
ul li .item-list {
    width: max-content !important;
}
</style>
